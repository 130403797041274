<template>
  <div>
    <v-flex xs12 sm12>
      <v-card
              class="mx-auto"
              style="text-align:center;border-radius:5px"
      >
        <v-card class="pt-8">
          <v-icon x-large class="primary--text" >
            mdi-check-circle-outline
          </v-icon>
          <v-card-subtitle>
            Your request has been successfully received. Our sales agent will contact you shortly.
          </v-card-subtitle>
          <v-card-actions class="text-center d-inline-block" >
            <v-btn rounded @click="completeCheckout" outlined color="primary">Complete</v-btn>
          </v-card-actions>
        </v-card>

      </v-card>
    </v-flex>

  </div>
</template>

<script>
export default {
  name: "Signed",
  data() {
    return {
      phone_verification: false,
      phone: "",
      phoneObj: {},
      vcode: ""
    };
  },
  mounted() {

  },
  methods:{
    completeCheckout(){
      this.$store.commit("clearCart");
      this.$router.push('/')
    },
  },
  computed: {

  }
};
</script>

<style scoped>
.google_color {
  color: #de5246;
}
.fup {
  border: 1px dashed rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 10px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<template>
      <v-card class="mb-5">

          <v-list subheader>
              <v-list-item
                      class="py-n4"
                      v-for="item in displayCart"
                      :key="item.id"
                      avatar @click=""
              >
                  <v-list-item-avatar>
                      <v-img
                              :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=30`"
                      ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                      <v-list-item-subtitle class="caption">Qty: <b>{{item.purchase_quantity}}</b> </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                                                    <span>
                                                        <v-list-item-title class="caption font-weight-bold">Sub: <b>{{ item.subtotal | currency}}</b></v-list-item-title>
                                                    <v-list-item-subtitle class="caption">Price: <b> {{item.price | currency}}</b> </v-list-item-subtitle>
                                                    </span>
                  </v-list-item-icon>
              </v-list-item>
          </v-list>
          <v-list subheader class="pull">
              <table class="pricing suheading">
                  <tr>
                      <td class="subheading">Total Cost:</td>
                      <td class="align-end header"> {{totalCost | currency}} </td>
                  </tr>
                  <tr>
                      <td class="subheading">Amount Due:</td>
                      <td class="align-end">{{totalCost | currency}} </td>
                  </tr>
              </table>
          </v-list>
      </v-card>
</template>
<script>
export default {
  name: "CartSummary",
  data() {
    return {
      items2: [
        {
          title: "Travis Howard",
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg"
        }
      ]
    };
  },
  computed: {
      upCart() {
      return this.$store.getters.getCart;
    },
    noItems() {
      return this.upCart.length;
    },
    totalCost() {
       let cost =  Object.values(this.upCart).reduce(
        (t, n) => t + Math.abs(parseFloat(n.subtotal)), 0);
        return cost.toFixed(2);
    },
      displayCart() {
          return this.$store.getters.getCart.slice(0,4);
      },
  },
  methods: {
    goCheckout() {
      this.$router.push("/cart");
    },
    removeItem(item) {
      let index = this.upCart.indexOf(item);
      this.upCart.splice(index, 1);
    }
  }
};
</script>

<style lang="scss">
.pricing {
  margin-left: 10px;
  font-weight: bold;
}

.pricing td:nth-last-child(1) {
  color: green;
  padding: 2px;
}
</style>

<template>
    <v-container grid-list-xl fluid>
      <v-subheader class="text-h5 font-weight-bold pl-2">Please Fill All Fields To Complete Checkout</v-subheader>
        <v-layout row wrap>
            <v-flex sm12 md12 >
                <v-stepper v-model="step" style="border-radius:20px" >
                    <div class="pt-10">
                      <ValidationObserver
                      ref="observer"
                      style="width: 100%"
                      v-slot="{ invalid }"
                      >
                        <v-row class="mx-0">
                          <v-col>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="name"
                                rules="required|min:3|max:25"
                            >
                              <v-text-field
                                  v-model="userDetails.name"
                                  outlined
                                  @input="validateField('name')"
                                  type="text"
                                  label="Enter Name"
                                  :error-messages="errors"
                              >
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="region"
                                rules="required"
                            >
                              <v-autocomplete
                                  v-model="userDetails.region"
                                  outlined
                                  @change="validateField('region')"
                                  label="Select Region"
                                  :items="['Accra', 'Ashanti', 'Brong Ahafo', 'Central', 'Eastern', 'North', 'Western', 'Volta']"
                                  :error-messages="errors"
                              >
                              </v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                          <v-col>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="location"
                                rules="required|min:2|max:30"
                            >
                              <v-text-field
                                  v-model="userDetails.location"
                                  outlined
                                  @input="validateField('location')"
                                  type="text"
                                  label="Enter Location"
                                  :error-messages="errors"
                              >
                              </v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </ValidationObserver>
                    </div>
                    <v-stepper-header flat>
                        <v-stepper-step
                                class="subheading"
                                color="primary"
                                step="1" :complete="step > 1">
                            Cart Items
                        </v-stepper-step>
                    </v-stepper-header>
                    <!--CART ITEMS DISPLAY-->
                    <v-stepper-items>
                        <v-stepper-content v-if="!requestComplete" step="1">
                            <v-card height="100%" flat class="mb-16">

                                <v-list subheader>
                                    <v-list-item
                                            style="border-bottom:1px solid #e3e3e3"
                                            class="py-n4"
                                            v-for="item in displayCart"
                                            :key="item.id"
                                    >
                                        <v-list-item-avatar
                                                width="50"
                                                height="50"
                                        >
                                            <v-img
                                                    width="150"
                                                    :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=80`"
                                            ></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">{{item.name}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.category.name}}</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-light"> <b class="font-weight-bold">{{item.purchase_quantity}}</b></v-list-item-title>
                                            <v-list-item-subtitle >quantity</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-light"><b class=" font-weight-bold">{{ item.price | currency }}</b></v-list-item-title>
                                            <v-list-item-subtitle caption>price</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-light"><b class="font-weight-bold">{{ item.subtotal | currency }}</b></v-list-item-title>
                                            <v-list-item-subtitle>subtotal</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon>
                                                    <span
                                                            class="grey--text">
                                               <v-icon small @click="removeItem(item)"
                                               >mdi-close-circle</v-icon
                                               ></span>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list>

                                <v-row class="mt-3" justify="space-between">
                                    <v-list subheader class="pull">
                                        <table class="pricing suheading">
                                            <tr>
                                                <td class="subheading">Total Cost:</td>
                                                <td class="align-end header">{{totalCost | currency}} </td>
                                            </tr>
                                            <tr>
                                                <td class="subheading">Amount Due:</td>
                                                <td class="align-end">{{totalCost | currency}} </td>
                                            </tr>
                                        </table>
                                    </v-list>
                                
                                    <div style="border: 0">
                                        <b style="font-size:x-small" class="ml-4 font-weight-bold text-subtitle-2 red--text text--darken-3">Please enter your phone number. </b>
                                        
                                        <akoko-phone
                                            :border-radius="5"
                                            @update="userPhoneNo"
                                            v-model="phone"
                                            size="lg"
                                            default-country-code="GH"
                                            style="border-color:blue;"
                                            :only-countries="['GH']"
                                        >
                                        </akoko-phone>
                                    </div>

                                    <div class="mx-3 my-2" style="display: flex; align-items: start; justify-content: space-around; flex-direction: column;">
                                    
                                        <v-btn class="py-0 mb-3" small style="text-transform:capitalize" width="180px" v-if="displayCart.length > 0" :disabled="!isValidPhone || isFieldsInvalid "
                                            color="primary" @click.native="submitRequest"> Request 
                                        </v-btn>
                                        <v-btn class="py-0 mb-3" small style="text-transform:capitalize" width="180px" v-else disabled
                                            color="primary" @click.native="submitRequest"> Request 
                                        </v-btn>
                                        <v-btn class="py-0" small width="180px" style="text-transform:capitalize" outlined color="primary" @click="cancelCart">Cancel</v-btn>

                                    </div>
                                
                                </v-row>
                            </v-card>
                            <v-spacer></v-spacer>
                            
                        </v-stepper-content>
                            <request-complete v-else></request-complete>
                    </v-stepper-items>
                </v-stepper>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import SignIn from "../../../components/Website/Customer/Signin";
    import Signed from "../../../components/Website/Customer/Signed";
    import CartSummary from "../../../components/Website/Customer/CartSummary";
    import RequestComplete from "../../../components/Website/Customer/RequestComplete";
    import axios from 'axios'
    import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
    import {min, max, required} from "vee-validate/dist/rules";
    import {firestore} from "firebase";

    import mixpanel from "mixpanel-browser";

    mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true, track_pageview: true });

    extend('required', {
      ...required,
      message: '{_field_} can not be empty',
    })

    extend('min', {
      ...min,
      message: '{_field_} must be more than {length} characters'
    })

    extend('max', {
      ...max,
      message: '{_field_} must not be more than {length} characters'
    })
    export default {
        name: "Cart",
        data(){
            return{
              userDetails: {
                name: '',
                region: null,
                location: ''
              },
              fieldErrors: {
                name: '',
                region: '',
                location: '',
              },
              active: false,
              errorMessage: '',
              phone_verification: false,
              phone: "",
              phoneObj: {},
                step: 1,
                e13: 1,
                requestComplete:false,
            }
        },
        computed: {
          isFieldsInvalid(){
            return (
                this.fieldErrors.name !== '' ||
                this.fieldErrors.region !== '' ||
                this.fieldErrors.location !== '' ||
                this.userDetails.name === '' ||
                this.userDetails.region === null ||
                this.userDetails.location === ''
            );
          },
          isValidPhone(){
            return this.phoneObj.isValid;
          },
            upCart() {
                return this.$store.getters.getCart;
            },
            noItems() {
                return this.upCart.length;
            },
            totalCost() {
                let cost =  Object.values(this.upCart).reduce(
                    (t, n) => t + Math.abs(parseFloat(n.subtotal)) , 0);
                return parseFloat(cost.toFixed(2));
            },
            displayCart() {
                return this.$store.getters.getCart;
            },
            loginState(){
                return this.$store.getters.loginState;
            },
        },

        methods:{
          userPhoneNo(payload) {
            this.phoneObj = payload;
          },

          validateField(fieldName) {
            this.$refs.observer.validate().then((result) => {
              if (result) {
                this.fieldErrors[fieldName] = '';
              } else {
                const errors = this.$refs[fieldName].errors;
                this.fieldErrors[fieldName] = errors.length > 0 ? errors[0] : '';
              }
            });
          },

          removeItem(item){
                this.$store.dispatch('removeItem',item);
          },

          cancelCart(){
                this.$store.commit("clearCart");
                this.$router.go(-1);
          },

          submitRequest(){
                let cart = this.$store.getters.getCart;
                let cartItems = [];

                for(let i=0; i<this.displayCart.length; i++){
                    // return axios.post('https://us-central1-akokomarket-d5e1f.cloudfunctions.net/smsAPI', {
                    // to: `+${this.item.company.phone}`,
                    // message
                    // }, {
                    //     headers: {
                    //         "APP_NAME": "AkokoMarket"
                    //     }
                    // })
                    console.log('////Phone Numbers///////')
                    console.log(this.displayCart)
                }


                //create cartItems
                for(let cartSet of cart){
                    cartItems.push(
                        {
                            qty:parseInt(cartSet.purchase_quantity),
                            user:{
                                connect:{
                                  id:this.$store.getters.guestID,
                                }
                            },
                            product:{
                                connect:{
                                    id:cartSet.id,
                                }
                            }
                        }
                    )
                }
                // Create data for Request
                let marketRequestCreatInput= {
                    phone:this.phone,
                    phone_operator:"mtn",
                    cost:this.totalCost,
                    status:"pending",
                    customer:{
                        connect:{
                          id:this.$store.getters.guestID,
                        }
                    },
                    products:{
                        create:cartItems,
                    }
                }
                let con = confirm('Are you sure you want to submit request?');
                if(con){
                    console.log({createInput:marketRequestCreatInput});
                    this.$store.dispatch('submitRequest',marketRequestCreatInput).then(res=>{
                        this.$store.commit('clearCart')
                        console.log({createRes:res});
                        this.requestComplete = true;
                        console.log("Request Submitted.")

                    }).catch(e=>{
                        console.log("yoo"+e);
                    });

                  const orders = cart.map((item) => {
                      mixpanel.track("Request Clicks", {
                      product: `${item.name}_requested`,
                      quantity: `${item.purchase_quantity}_quantity requested`
                    })
                    // console.log(item,"-------------------printing request item");
                    delete item.company
                    return {
                      client_location: this.userDetails.location,
                      client_name: this.userDetails.name,
                      client_phone: this.phoneObj.formattedNumber.replace('+', ""),
                      client_quantity: item.purchase_quantity,
                      region: this.userDetails.region,
                      network_provider: "",
                      completed: true,
                      source: 'e-commerce',
                      category: { ...item.product_type },
                      product: {
                        ...item,
                        price: item.price,
                        total: item.purchase_quantity * item.price
                      } ,
                      service: {
                        acceptDetails: true,
                        enabled: true,
                        id: "5xC0vGfzIWzhCxnd461l",
                        index: "0",
                        name: "Buy",
                      },
                      transaction: "cash",
                      session_id: "",
                      created_at: new Date(),
                      updated_at: new Date(),
                    };
                  });

                  let batch = firestore().batch();

                  orders.forEach((o) => {
                    firestore().collection("requests").doc() //automatically generate unique id
                    .set({
                      ...o,
                      direct: false,
                      session_id: "",
                    });
                  });
                }
            },
        },
        components:{
            Signed,
            SignIn,
            CartSummary,
            RequestComplete,
            ValidationObserver,
            ValidationProvider
        },

      mounted() {
        console.log('jack', this.$store.getters.getCart)
      }
    }
</script>

<style scoped>

</style>

<template>
        <div class="">
            <v-container grid-list-xl  fluid>
              <v-subheader class="text-h5 font-weight-bold pl-2 mb-2">Please Fill All Fields To Complete Checkout</v-subheader>
                <v-layout row wrap>
                    <v-flex sm12 xs12>
<!--                        <v-subheader>Please Complete checkout</v-subheader>-->
                        <v-stepper v-model="step" style="border-radius:20px" height="">
                            <div class="pt-10">
                              <ValidationObserver
                                  ref="observer"
                                  style="width: 100%"
                                  v-slot="{ invalid }"
                              >
                                <v-row class="mx-0">
                                  <v-col cols="12">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="name"
                                        rules="required|min:3|max:25"
                                    >
                                      <v-text-field
                                          v-model="userDetails.name"
                                          outlined
                                          @input="validateField('name')"
                                          type="text"
                                          label="Enter Name"
                                          :error-messages="errors"
                                      >
                                      </v-text-field>
                                    </ValidationProvider>
                                  </v-col>
                                  <v-col cols="12">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="region"
                                        rules="required"
                                    >
                                      <v-autocomplete
                                          v-model="userDetails.region"
                                          outlined
                                          @change="validateField('region')"
                                          label="Select Region"
                                          :items="['Accra', 'Ashanti', 'Brong Ahafo', 'Central', 'Eastern', 'North', 'Western', 'Volta']"
                                          :error-messages="errors"
                                      >
                                      </v-autocomplete>
                                    </ValidationProvider>
                                  </v-col>
                                  <v-col cols="12">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="location"
                                        rules="required|min:2|max:30"
                                    >
                                      <v-text-field
                                          v-model="userDetails.location"
                                          outlined
                                          @input="validateField('location')"
                                          type="text"
                                          label="Enter Location"
                                          :error-messages="errors"
                                      >
                                      </v-text-field>
                                    </ValidationProvider>
                                  </v-col>
                                </v-row>
                              </ValidationObserver>
                            </div>
                            <v-stepper-header flat>
                                <v-stepper-step
                                        class="subheading"
                                        color="primary"
                                        step="1" :complete="step > 1">
                                    Cart Items
                                </v-stepper-step>
                            </v-stepper-header>
                            <!--CART ITEMS DISPLAY-->
                            <v-stepper-items>
                                <v-stepper-content v-if="!requestComplete" step="1">
                                    <v-card flat class="mb-5">
                                        <v-list subheader>
                                            <v-list-item
                                                    class="py-n4"
                                                    v-for="item in displayCart"
                                                    :key="item.id"
                                            >
                                                <v-list-item-avatar>
                                                    <v-img
                                                            :src="`${imgHost}imgs/products/${item.product_type.slug}/${item.img_url}?w=30`"
                                                    ></v-img>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="mx-2">
                                                    <v-list-item-title style="font-size:small" class="font-weight-bold" v-text="item.name"></v-list-item-title>
                                                    <v-list-item-subtitle style="font-size:x-small">Qty: <b>{{item.purchase_quantity}}</b> </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-icon>
                                                    <span>
                                                        <v-list-item-title style="font-size:small" class="font-weight-bold">Sub: <b>{{ item.subtotal | currency }}</b></v-list-item-title>
                                                    <v-list-item-subtitle style="font-size:x-small">Price: <b> {{item.price | currency}}</b> </v-list-item-subtitle>
                                                    </span>
                                                </v-list-item-icon>
                                                <v-list-item-icon>
                                                    <span
                                                            class="grey--text">
                                               <v-icon small @click="removeItem(item)"
                                               >mdi-close-circle</v-icon
                                               ></span>
                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                        <v-list subheader class="pull">
                                            <table class="pricing suheading">
                                                <tr>
                                                    <td class="subheading">Total Cost:</td>
                                                    <td class="align-end header">{{totalCost | currency}} </td>
                                                </tr>
                                                <tr>
                                                    <td class="subheading">Amount Due:</td>
                                                    <td class="align-end">{{totalCost | currency}} </td>
                                                </tr>
                                            </table>
                                        </v-list>
                                    </v-card>
                                    <v-spacer></v-spacer>
                                  <v-layout row>
                                    <v-flex xs12>
                                      <b style="font-size:x-small" class="ml-4 red--text text--darken-3 text-subtitle-2 font-weight-bold">Please enter your phone number. </b>
                                      <akoko-phone
                                          @update="userPhoneNo"
                                          v-model="phone"
                                          size="lg"
                                          default-country-code="GH"
                                      >
                                      </akoko-phone>
                                    </v-flex>
                                  </v-layout>
                                  <v-card-actions class="justify-center" >
                                    <v-btn class="px-5 py-5" style="text-transform: capitalize;" color="primary" outlined @click="cancelCart">Cancel</v-btn>
                                    <v-btn class="px-5 py-5" style="text-transform: capitalize"  v-if="displayCart.length > 0" :disabled="!isValidPhone || isFieldsInvalid"
                                           color="primary" @click.native="submitRequest"> Request </v-btn>
                                    <v-btn class="px-5 py-5" style="text-transform: capitalize"  v-else disabled
                                           color="primary" @click.native="submitRequest"> Request</v-btn>
                                  </v-card-actions>
                                </v-stepper-content>
                              <request-complete v-else>
                              </request-complete>
                            </v-stepper-items>
                        </v-stepper>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
</template>

<script>
    import MiniSignIn from "../../../components/Website/Customer/MiniSignin";
    import Signed from "../../../components/Website/Customer/Signed";
    import CartSummaryMini from "../../../components/Website/Customer/CartSummaryMini";
    import RequestComplete from "../../../components/Website/Customer/RequestComplete";
    import {firestore} from "firebase";
    import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
    import {required, min, max} from "vee-validate/dist/rules";

    extend('required', {
      ...required,
      message: '{_field_} can not be empty',
    })

    extend('min', {
      ...min,
      message: '{_field_} must be more than {length} characters'
    })

    extend('max', {
      ...max,
      message: '{_field_} must not be more than {length} characters'
    })

    export default {
        name: "MiniCart",
        data(){
            return{
              userDetails: {
                name: '',
                region: null,
                location: ''
              },
              fieldErrors: {
                name: '',
                region: '',
                location: '',
              },
              phone_verification: false,
              phone: "",
              phoneObj: {},
              step: 1,
              e13: 1,
              requestComplete:false,
            }
        },
        computed: {
          isFieldsInvalid(){
            return (
                this.fieldErrors.name !== '' ||
                this.fieldErrors.region !== '' ||
                this.fieldErrors.location !== '' ||
                this.userDetails.name === '' ||
                this.userDetails.region === null ||
                this.userDetails.location === ''
            );
          },
          isValidPhone(){
            return this.phoneObj.isValid;
          },
            upCart() {
                return this.$store.getters.getCart;
            },
            noItems() {
                return this.upCart.length;
            },
            totalCost() {
                let cost =  Object.values(this.upCart).reduce(
                    (t, n) => t + Math.abs(parseFloat(n.subtotal)), 0);
                return parseFloat(cost.toFixed(2));
            },
            displayCart() {
                return this.$store.getters.getCart;
            },
            loginState(){
                return this.$store.getters.loginState;
            },
        },

        methods:{
          validateField(fieldName) {
            this.$refs.observer.validate().then((result) => {
              if (result) {
                this.fieldErrors[fieldName] = '';
              } else {
                const errors = this.$refs[fieldName].errors;
                this.fieldErrors[fieldName] = errors.length > 0 ? errors[0] : '';
              }
            });
          },

          userPhoneNo(payload) {
            this.phoneObj = payload;
          },
            removeItem(item){
                this.$store.dispatch('removeItem',item);
            },
            cancelCart(){
                this.$store.commit("clearCart");
                this.$router.go(-1);
            },
            submitRequest(){
                let cart = this.$store.getters.getCart;
                let cartItems = [];

                //create cartItems
                for(let cartSet of cart){
                     cartItems.push(
                        {
                           qty:parseInt(cartSet.purchase_quantity),
                            user:{
                               connect:{
                                 id:this.$store.getters.guestID,
                               }
                            },
                            product:{
                               connect:{
                                   id:cartSet.id,
                               }
                            }
                        }
                    )
                }
                // Create data for Request
                let marketRequestCreatInput= {
                    phone:this.phone,
                    phone_operator:"mtn",
                    cost:this.totalCost,
                    status:"pending",
                    customer:{
                        connect:{
                            id:this.$store.getters.guestID,
                        }
                    },
                    products:{
                        create:cartItems,
                    }
                }
                let con = confirm('Are you sure you want to submit request?');
                if(con){
                    this.$store.dispatch('submitRequest',marketRequestCreatInput).then(res=>{
                        this.$store.commit('clearCart')
                        this.requestComplete = true;
                        console.log("Request Submitted.")
                    }).catch(e=>{
                        console.log(e);
                    });


                  const orders = cart.map((item) => {
                    return {
                      client_location: this.userDetails.location,
                      client_name: this.userDetails.name,
                      client_phone: this.phoneObj.formattedNumber.replace("+", ""),
                      client_quantity: item.purchase_quantity,
                      region: this.userDetails.region,
                      network_provider: "",
                      completed: true,
                      source: 'e-commerce',
                      category: { ...item.product_type },
                      product: {
                        ...item,
                        price: item.price,
                        total: item.purchase_quantity * item.price
                      } ,
                      service: {
                        acceptDetails: true,
                        enabled: true,
                        id: "5xC0vGfzIWzhCxnd461l",
                        index: "0",
                        name: "Buy",
                      },
                      transaction: "cash",
                      session_id: "",
                      created_at: new Date(),
                      updated_at: new Date(),
                    };
                  });

                  let batch = firestore().batch();

                  orders.forEach((o) => {
                    firestore().collection("requests").doc() //automatically generate unique id
                        .set({
                          ...o,
                          direct: false,
                          session_id: "",
                        });
                  });
                }
            },
        },
        components:{
            Signed,
            MiniSignIn,
            CartSummaryMini,
            RequestComplete,
            ValidationObserver,
            ValidationProvider
        },

    }
</script>

<style scoped>

</style>

<template>
    <v-col cols="12">
        <v-row justify="center" :class="isMobile?'my-4 mx-1':'my-4'">
        <div :class="isMobile?'':'col-10 col-sm-8'">
            <mini-cart v-if="isMobile"></mini-cart>
            <full-cart v-else></full-cart>
        </div>
    </v-row>
    </v-col>
</template>

<script>
    import FullCart from "./FullCart";
    import MiniCart from "./MiniCart";
    import SignIn from "../../../components/Website/Customer/Signin";
    import Signed from "../../../components/Website/Customer/Signed";
    import CartSummary from "../../../components/Website/Customer/CartSummary";
    import RequestComplete from "../../../components/Website/Customer/RequestComplete";
    export default {
        name: "Cart",
        data(){
            return{
                step: 1,
                e13: 1,
                requestComplete:false,
            }
        },
        computed: {
            upCart() {
                return this.$store.getters.getCart;
            },
            noItems() {
                return this.upCart.length;
            },
            totalCost() {
                let cost =  Object.values(this.upCart).reduce(
                    (t, n) => t + Math.abs(parseFloat(n.subtotal)), 0);
                return parseFloat(cost.toFixed(2));
            },
            displayCart() {
                return this.$store.getters.getCart;
            },
            loginState(){
                return this.$store.getters.loginState;
            },
        },

        methods:{
            removeItem(item){
                this.$store.dispatch('removeItem',item);
            },
            cancelCart(){
                this.$store.commit("clearCart");
                this.$router.go(-1);
            },
            submitRequest(){
                let cart = this.$store.getters.getCart;
                let cartItems = [];

                //create cartItems
                for(let cartSet of cart){
                     cartItems.push(
                        {
                           qty:parseInt(cartSet.purchase_quantity),
                            user:{
                               connect:{
                                   id:this.userData.id,
                               }
                            },
                            product:{
                               connect:{
                                   id:cartSet.id,
                               }
                            }
                        }
                    )
                }
                // Create data for Request
                let marketRequestCreatInput= {
                    phone:this.userData.phone,
                    phone_operator:"mtn",
                    cost:this.totalCost,
                    status:"pending",
                    customer:{
                        connect:{
                            id:this.userData.id,
                        }
                    },
                    products:{
                        create:cartItems,
                    }
                }
                let con = confirm('Are you sure you want to submit request?');
                if(con){
                    console.log({createInput: marketRequestCreatInput});
                    this.$store.dispatch('submitRequest',marketRequestCreatInput).then(res=>{
                        this.firebase.analytics().logEvent('RequestMade', {
                            product: marketRequestCreatInput.id,
                            user: this.userData.id
                        })
                        console.log({createRes:res});
                        this.$store.commit('clearCart')
                        this.requestComplete = true;
                        console.log("Request Submitted.")
                    }).catch(e=>{
                        console.log(e);
                    });
                }
            },
        },
        components:{
            FullCart,
            MiniCart,
            Signed,
            SignIn,
            CartSummary,
            RequestComplete,
        },

    }
</script>

<style scoped>

</style>

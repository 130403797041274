<template>
  <div>
    <v-flex xs12 sm12>
      <v-card
              class="mx-auto"
              :max-width="isMobile?'150':'300'"
              style="text-align:center;border-radius:20px"
      >

          <v-img
                  :width="isMobile?'150':'300'"
                  src="http://akokomarket.imgix.net/male.gif"
                  lazy-src="http://akokomarket.imgix.net/male.gif?w=10"
          >
            <template v-slot:placeholder>
              <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
<!--        :src="`${imgHost}imgs/category/${$route.params.slug}.png`"-->
        <v-list two-line>
          <v-list-item @click="">
            <v-list-item-icon v-if="!isMobile" class="pa-n4">
              <v-icon color="primary">mdi-at</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{userData.first_name}}</v-list-item-title>
              <v-list-item-subtitle class="caption">{{userData.phone}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon v-if="!isMobile">
              <v-icon>mdi-refresh</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>

      </v-card>
    </v-flex>

  </div>
</template>

<script>
export default {
  name: "Signed",
  data() {
    return {
      phone_verification: false,
      phone: "",
      phoneObj: {},
      vcode: ""
    };
  },
  mounted() {

  },
  computed: {

  }
};
</script>

<style scoped>
.google_color {
  color: #de5246;
}
.fup {
  border: 1px dashed rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 10px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
